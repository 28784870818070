import { Component} from '@angular/core';
import { HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from './services/core/auth/auth.service';
import { environment } from '../environments/environment';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

declare const gtag: Function;

@HostListener('window:popstate', ['$event'])

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent {
  constructor(
      public router:Router,
      public authService: AuthService,
      private matIconRegistry: MatIconRegistry,
      private domSanitizer: DomSanitizer
  ) {
    // Register country icons
    this.registerCountryIcons();
    
    if (environment.production) {
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        /** START : Code to Track Page View  */
         gtag('event', 'page_view', {
            page_path: event.urlAfterRedirects
         })
        /** END */
      })
    }
  }
  
  private registerCountryIcons() {
    // Common country codes
    const countryCodes = ['fr', 'us', 'gb', 'de', 'es', 'it', 'ca', 'au', 'jp', 'cn', 'in', 'br'];
    
    // Register each country icon
    countryCodes.forEach(code => {
      this.matIconRegistry.addSvgIcon(
        code,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/svg-country-flags/svg/${code}.svg`)
      );
      
      // Also register with colon prefix for backward compatibility
      this.matIconRegistry.addSvgIcon(
        `:${code}`,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/svg-country-flags/svg/${code}.svg`)
      );
    });
  }
  
  onPopState(event) {
    location.reload()
  }
  
  ngOnInit(){
  }
}
