import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { AuthService } from '../auth/auth.service';
import { routes } from '../../../consts';
import { UserStateService } from 'src/app/services/common/user/user-state.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService  {
  public routers: typeof routes = routes;

  constructor(
    private router: Router, 
    private authService: AuthService,
    private userStateService: UserStateService
  ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>{   
    const accessToken = localStorage.getItem(
      ConstantService.localStorageKeys.access_Token
    );

    const userId = localStorage.getItem(
      ConstantService.localStorageKeys.userId
    );
    try {
      if (!accessToken && !userId) {
        this.router.navigate(['/auth/login']);
        return false;
      }
      const user = await this.userStateService.loadUser(userId);
      const isSharedWithUser = await this.authService.checkSharedWithUser(user.user.email);

      if (user.user.subStatus === "active" || isSharedWithUser)  {
      
      return true;

      } else {

        this.router.navigate(['/buyPlan']);
        return false;
      }
    } catch (error: any) {
      console.error("Auth Guard Error:", error);
      // Check for 401 error
      if (error.status === 401 || error?.error?.message?.includes('JWT')) {
        // Clear all storage on token expiration
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['/auth/login']);
      } else {
        this.router.navigate(['/buyPlan']);
      }
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthGuardBuyPlanService {
  constructor(private router: Router, private authService: AuthService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const accessToken = localStorage.getItem(
      ConstantService.localStorageKeys.access_Token
    );

    const userId = localStorage.getItem(
      ConstantService.localStorageKeys.userId
    );
    
    if (!accessToken || !userId) {
    
      this.router.navigate(['/auth/login']);
      return false;
    }
  }
}


@Injectable({
  providedIn: 'root',
})
export class NonAuthGuardService  {
  constructor( private router: Router) {}

  canActivate() {
    const accessToken = localStorage.getItem(
      ConstantService.localStorageKeys.access_Token
    );
    const userId = localStorage.getItem(
      ConstantService.localStorageKeys.userId
    );

    if (accessToken && userId) {
      this.router.navigate(['/home']);
      return false;
    }
    return true;
  }
}
