import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { provideHttpCache } from '@ngneat/cashew';
import { provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiRegisterService } from './services/api-register.service';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MaterialModule } from './shared/module/material/material.module';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { HelperService } from 'src/app/shared/helper/helper.service';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { SharedModule } from './shared/shared.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { AddInternalPagesOnlyComponent } from './add-internal-pages-only/add-internal-pages-only.component';
import { FirstPartyInternalDetailComponent } from './features/home/first-party-internal-detail/first-party-internal-detail.component';
import { HomeModule } from './features/home/home.module';
@NgModule({ declarations: [
        AppComponent,
        PageNotFoundComponent,
        DashboardComponent,
        AddInternalPagesOnlyComponent,
        FirstPartyInternalDetailComponent,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [MatNativeDateModule,
        SharedModule,
        MatDialogModule,
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgApexchartsModule,
        BrowserAnimationsModule,
        RouterModule,
        CommonModule,
        LayoutModule,
        MatTableModule,
        MatSortModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MaterialModule,
        RxReactiveFormsModule,
        MatSlideToggleModule,
        NgCircleProgressModule.forRoot({
            radius: 32,
            space: -10,
            outerStrokeGradient: true,
            outerStrokeWidth: 8,
            outerStrokeColor: '#2fbba5',
            outerStrokeGradientStopColor: '#53a9ff',
            innerStrokeColor: '#e7e8ea',
            innerStrokeWidth: 8,
            animateTitle: false,
            animationDuration: 1000,
            showUnits: false,
            showTitle: true,
            showBackground: false,
            clockwise: false,
            showImage: false,
            subtitleFontSize: '12',
            showSubtitle: true,
            startFromZero: false,
            lazy: true,
        }),
        HomeModule], providers: [
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_DIALOG_DATA, useValue: {} },
        HelperService,
        ApiRegisterService,
        provideHttpClient(
            withInterceptorsFromDi(),
            withJsonpSupport()
        ),
        provideHttpCache(),
    ] })
export class AppModule {}
