import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { InternalPageDetailComponent } from './features/home/internal-page-detail/internal-page-detail.component';
import { MonitorSiteComponent } from './features/home/monitor-site/monitor-site.component';
import { InsightsViewerComponent } from './features/home/insights-viewer/insights-viewer.component';

import {
  AuthGuardBuyPlanService,
  AuthGuardService,
  NonAuthGuardService,
} from './services/core/guards/auth-guard.service';
import { BuyPlanComponent } from './features/buy-plan/buy-plan.component';

const routes: Routes = [
  {
    path: 'auth',
    canActivate: [NonAuthGuardService],
    loadChildren: () =>
      import('./features/loginregister/loginregister.module').then(
        (m) => m.LoginregisterModule,
      ),
  },
  {
    path: 'home',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./features/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'demo',
    loadChildren: () =>
      import('./features/demo-project/demo-project.module').then(
        (m) => m.DemoProjectModule,
      ),
  },
  {
    path: 'demo/page/:id/internalpages/details/:url',
    component: InternalPageDetailComponent,
  },

  { path: 'monitor-site', component: MonitorSiteComponent },
  { path: 'buyPlan',
    canActivate: [AuthGuardBuyPlanService],
   component: BuyPlanComponent,
  },
  {
    path: 'home/page/:projectId/insights',
    component: InsightsViewerComponent
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    useHash: false
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
